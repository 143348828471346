<template>
    <div class="forget-mobile" >
        <div class="emailForm" v-if="step==1">
            <div class="formheader">
                {{title}}
            </div>
            <el-form ref="form" :model="form" label-width="140px" :rules="rules" label-position="top">
                <el-form-item class="inputitem" :label="emailLabel" prop="email">
                    <el-input  v-model="form.email" :placeholder="emailPlaceholder" ></el-input>
                </el-form-item>
                <div class="step1-mid">
                    <div class="bottomnotice">{{noticetext}}</div>
                </div>
                <el-button type="primary" class="step3-button" :disabled="resetDisabled" @click.native="doReset()">{{buttonText}}</el-button>
                <div class="step3-bottom">
                    <div class="bottonnotice">{{noticeText1}}</div>
                    <router-link to="/loginMobile" class="link" style="margin-left: 5px" replace>{{loginText}}</router-link>
                </div>
            </el-form>
        </div>
        <!-- step 2-->
        <div class="emailForm" v-if="step==2">
            <div class="formheader" >
                {{mailtitle}}
            </div>
            <div class="verifyline">
                <div class="content">
                    <div class="eline">
                        <div class="emailline">{{emailLabel}}</div>
                        <div class="emailline" style="margin-left: 10px">{{form.email}}</div>
                    </div>

                    <div class="nodity">{{mailNotifyContent}}</div>
                    <div class="clickedinfo">{{clickinfo}}</div>
                </div>
            </div>
            <div class="step2-bottom">
                <router-link to="" class="link" @click.native="sendAnotherMail()">{{mailNotArrive}}</router-link>
                <router-link to="/loginMobile" class="link">{{gobackToEasyTrade}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import index from './index'
    export default index
</script>

<style lang="less">
    .forget-mobile {
        padding: 20px;
        .formheader{
            margin-top: 8px;
            color:#333333;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 30px;
            font-weight: bold;
        }
        .inputitem{
            /*padding: 0px 40px 0px 40px;*/
        }
        .inputitem-left{
            /*padding: 0px 0px 0px 40px;*/
        }
        .inputitem-right{
            /*padding: 0px 40px 0px 0px;*/
        }
        .button{
            /*margin: 40px 90px 40px 90px;*/
            width: 420px;
            height: 70px;
            background-color: #4173fb;
            box-shadow: 0 0 10px 0 rgba(0,115,255,0.20);
            font-size: 28px;
        }
        .emptyline{
            height: 30px;
        }
        .emailForm{
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        .emailForm .verifyline{
            height: 120px;
            display: flex;
            .content{
                color:#333333
            }
            .eline{
                display: flex;
                line-height: 22px;
                font-size: 16px;
                color:#333333
            }
            .emailline{
                font-size: 16px;
                line-height: 22px;
                color:#333333
            }
            .nodity {
                margin-top: 30px;
                font-size: 14px;
                line-height: 20px;
                color: #666666;
            }
            .clickedinfo{
                color:#BDC3D2;
                font-size: 14px;
            }

        }
        .eline{
            display: flex;
        }
        .emailinfo {
            font-size: 16px;
            line-height: 22px;
        }
        .emailForm .icon{
            width: 100px;
            height: 100px;
            background:url(../../../assets/images/mail.png) no-repeat 0 0;
            background-size: 100% 100%;
        }
        .emailForm .notice{
            width: 520px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #81899E;
        }
        .step2-bottom{
            width: 100%;
            height: 100px;
            margin-bottom: 20px;
            border-top: 1px solid #EEEEEE;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .link{
                margin-top: 30px;
                font-size: 14px;
                color: #0073FF;
                letter-spacing: 0;
                cursor: pointer;
            }
        }
        .step3-button{
            margin-top: 30px;
            width: 100%;
            height: 50px;
            background-color: #0073FF;
            box-shadow: 0 0 10px 0 rgba(0,115,255,0.20);
            font-size: 18px;
        }
        .step3-bottom{
            margin-top: 30px;
            width: 100%;
            /*height: 80px;*/
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
        }
        .bottonnotice{
            color: #ACBDC7;
        }
        .link{
            font-size: 14px;
            color: #0073FF;
            letter-spacing: 0;
            cursor: pointer;
        }

        .step1-mid{
            margin-top: 30px;
            width: 100%;
            display: flex;
            justify-content: center;
            /*text-align: center;*/
            align-items: center;
            color: #ACBDC7;
        }

        .emailForm .el-form-item__label {
            line-height: 20px;
        }
    }
</style>